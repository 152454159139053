const navLinks = [
    {
        name: 'About Me',
        link: 'about-me'
    },
    {
        name: 'Resume',
        link: 'resume'
    },
    {
        name: 'Contact',
        link: 'contact'
    }
];

export default navLinks;
